import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./privacy_policy.css"
import "./services_agreement.css"

const ServicesAgreement = () => {
  return (
    <Layout page="terms">
      <SEO title="Services Agreement" description="NEON COAT, INC., SERVICES AGREEMENT" />
      <div className="flex-grid">
        <div className="col" />
        <div style={{ flex: "0 1 994px", marginTop: "50px" }}>
          <div className="services-agreement-content">
            <div
              className="privacy-policy-title"
              style={{ marginTop: "-20px" }}
            >
              NEON COAT, INC. SERVICES AGREEMENT
            </div>
            <div className="privacy-policy-text">
              <p>This Services Agreement (the &ldquo;Agreement&rdquo;) is made and entered into by and between you (&ldquo;Organizer&rdquo;) and Neon Coat, Inc. (&ldquo;Neon Coat&rdquo;) (each a &ldquo;Party&rdquo; and together, the &ldquo;Parties&rdquo;) as of the date of your acceptance of its terms by paying a Neon Coat invoice (the &ldquo;Effective Date&rdquo;). This Agreement sets forth the terms pursuant to which Organizer will be permitted to use Neon Coat&rsquo;s services (the &ldquo;Neon Coat Services&rdquo;), as further described in the terms of service (&ldquo;TOS&rdquo;) available <a href="https://neoncoat.com/terms_brands">here</a>.</p>
              <p>The Parties hereby agree as follows:</p>

              <ol>
                <li>USE OF THE NEON COAT SERVICES</li>
                <li value={1}><span>Use License</span>. Subject to the terms and conditions of this Agreement, Neon Coat grants to Organizer a limited, worldwide, non-exclusive, non-transferable (except as otherwise expressly permitted herein) right to use the Neon Coat Services for as long as Organizer remains current on its payments for the Neon Coat Services. Organizer&rsquo;s right to use the Neon Coat Services is subject to all applicable TOS.</li>
                <li><span>Access and Users</span>. Organizer is responsible for managing access to its Neon Coat account (the &ldquo;Account&rdquo;). Organizer may permit its agents or service providers to access the Neon Coat Services through its Account, provided that Organizer ensures that any person or entity using its Account comply with the terms of this Agreement, and that Organizer remains responsible for any action taken using its Account.&nbsp;</li>
                <li><span>Use Restrictions</span>. Organizer may use the Neon Coat Services solely for its own business operations. Organizer may not resell Neon Coat Services to third parties, and may not, nor permit or authorize third parties to: (a) rent, lease, or otherwise permit third parties to use the Neon Coat Services; (b) use the Neon Coat Services to provide services to third parties; (c) circumvent or disable any security or other technological features or measures of the Neon Coat Services, or (d) reverse engineer any element of the Neon Coat Services, or use the Neon Coat Services or any of Neon Coat&rsquo;s Confidential Information (as defined below) to create a product or service that competes with the Neon Coat Services.</li>
                <li><span>Compliance</span>. Organizer shall use the Neon Coat Services in compliance with all applicable laws and regulations (including, without limitation, the FTC&rsquo;s Endorsement Guides) and in a manner that does not infringe on the rights of any third party.</li>
                <li><span>Unauthorized Use</span>. Organizer shall use reasonable efforts to prevent any unauthorized use of the Neon Coat Services and immediately notify Neon Coat in writing of any unauthorized use that comes to Organizer&rsquo;s attention. If there is unauthorized use by anyone who obtained access to the Neon Coat Services directly or indirectly through Organizer, Organizer shall take all steps reasonably necessary to terminate the unauthorized use. Organizer shall cooperate and assist with any actions taken by Neon Coat to prevent or terminate unauthorized use of the Neon Coat Services.</li>
                <li><span>Service Suspension</span>. Neon Coat may suspend Organizer&rsquo;s use of the Neon Coat Services if Neon Coat reasonably and in good faith believes such suspension is necessary to prevent unauthorized use of the Neon Coat Services or to prevent an ongoing violation of any applicable laws, regulations or TOS. Neon Coat shall use commercially reasonable efforts to notify Organizer prior to any such suspension and will only suspend the Neon Coat Services to the extent necessary to prevent such unauthorized use or violation. In addition, if Organizer fails to timely pay any fees in accordance with the terms of this Agreement, Neon Coat may, without limitation to any of its other rights or remedies, suspend performance of the Neon Coat Services until it receives all amounts due.</li>
                <li><span>Reservation of Rights</span>. Neon Coat grants to Organizer a limited right to use the Neon Coat Services under this Agreement. Organizer shall not have any rights to the Neon Coat Services except as expressly granted in this Agreement. Neon Coat reserves to itself and its licensors all rights to the Neon Coat Services not expressly granted to Organizer in accordance with this Agreement. Neon Coat and its licensors retain all copyright, patent, and other intellectual property rights in and to the Neon Coat Services.</li>
              </ol>

              <ol start="2">
                <li>FEES AND PAYMENT</li>
                <li value={1}><span>Fees</span>. Organizer shall pay Neon Coat for the Neon Coat Services in accordance with the prices established by Neon Coat from time to time and separately agreed between Organizer and Neon Coat (via separate invoice or otherwise) (the &ldquo;Fee&rdquo;). The Fee is subject to increase in Neon Coat&rsquo;s sole discretion, but any such increase may only be implemented after providing Organizer at least 30 days&rsquo; advance written notice of such increase, and allowing Organizer to cancel the Neon Coat Services without further charge if Organizer does not agree to such increase.</li>
                <li><span>Payment Terms</span>. Organizer shall pay all amounts due in advance on a one-time, monthly or annual basis (as applicable) by credit or debit card, ACH transfer, or such other payment method as expressly authorized in writing by Neon Coat. Absent a material breach of this Agreement or the TOS by Neon Coat, all Fee payments are final and non-refundable.</li>
                <li><span>Automatic Payments</span>. BY ENTERING BILLING INFORMATION VIA THE NEON COAT WEBSITE OR APPLICATION, ORGANIZER AUTHORIZES NEON COAT TO INITIATE RECURRING AUTOMATED CLEARING HOUSE (ACH) PAYMENTS FROM THE CHECKING OR SAVINGS ACCOUNT SPECIFIED, OR TO INITIATE RECURRING CHARGES FROM ORGANIZER&rsquo;S SPECIFIED CREDIT CARD. SUCH AUTOMATIC PAYMENTS WILL COMMENCE AT THE EXPIRATION OF ORGANIZER&rsquo;S FREE TRIAL PERIOD (IF ANY), UNLESS ORGANIZER CANCELS THE NEON COAT SERVICE AT LEAST 24 HOURS PRIOR TO SUCH EXPIRATION. Neon Coat will use best efforts to notify Organizer in advance of the first scheduled automatic payment. Unless other arrangements are made between Organizer and Neon Coat, automatic payments will be processed monthly in advance until canceled.&nbsp;</li>
                <li><span>Taxes</span>. Other than net income taxes imposed on Neon Coat, Organizer shall bear all taxes, duties, and other governmental charges resulting from its purchase or use of the Neon Coat Services.</li>
                <li><span>Cancellation</span>. Organizer&rsquo;s Neon Coat Services subscription (if applicable) will continue in effect on a month-to-month or year-to-year basis (as applicable) unless and until Organizer cancels the subscription or the account or service is otherwise suspended or discontinued in accordance with this Agreement. Organizer must cancel the subscription at least 24 hours before it renews each month or year (as applicable) to avoid the next billing. Neon Coat will charge the monthly or annual subscription fee (as applicable) to the payment method provided during the onboarding process (or to a different payment method if Organizer has subsequently designated a change to its account information). Cancellations will be effective at the end of the current billing period, which means Organizer will have continued access to the Neon Coat Services for the remainder of that period, but will not be entitled to a refund. Cancellations must be made by contacting customer service at <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>.&nbsp;</li>
              </ol>

              <ol start="3">
                <li>TERM AND TERMINATION</li>
                <li value={1}><span>Term</span>. This Agreement will commence on the Effective Date and remain in effect until Organizer cancels its subscription (if applicable), the Neon Coat Services for which Organizer is paying for are concluded, or until this Agreement is earlier terminated in accordance with its provisions.&nbsp;</li>
                <li><span>Termination for Cause</span>. Either Party may terminate this Agreement (i) upon thirty days&rsquo; written notice to the other Party of a material breach if such breach remains uncured at the expiration of such period, or (ii) immediately upon written notice if the other Party becomes the subject of a petition in bankruptcy or any other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of creditors.</li>
                <li><span>Post-Termination Obligations</span>. If this Agreement is terminated for any reason, any and all liabilities accrued prior to the effective date of the termination will survive, and Organizer will discontinue all use of the Neon Coat Services. All provisions of this Agreement that, by their nature, are intended to survive termination (including those related to third-party claims and limitations on liability) will remain in effect.</li>
              </ol>

              <ol start="4">
                <li>CONFIDENTIALITY</li>
                <li value={1}><span>Definition</span>. For purposes of this Agreement, &ldquo;Confidential Information&rdquo; means non-public information of Neon Coat or Organizer disclosed by either Party to the other Party, either directly or indirectly, in writing, orally or by inspection of tangible objects, or to which the other Party may have access, or any other information that a reasonable person would consider confidential and/or that is marked &ldquo;confidential&rdquo; or &ldquo;proprietary&rdquo; or some similar designation by the disclosing Party. Confidential Information will not, however, include any information that (i) was publicly known and made generally available in the public domain prior to the time of disclosure by the disclosing Party; (ii) becomes publicly known and made generally available after disclosure by the disclosing Party to the receiving Party through no action (or inaction) of the receiving Party; (iii) is already in the possession of the receiving Party at the time of disclosure by the disclosing Party, as clearly shown by the receiving Party&rsquo;s files and records; (iv) is obtained by the receiving Party from a third party without a breach of the third party&rsquo;s obligations of confidentiality; or (v) is independently developed by the receiving Party without use of or reference to the disclosing Party&rsquo;s Confidential Information, as clearly shown by documents and other competent evidence in the receiving Party&rsquo;s possession.</li>
                <li><span>Protection of Confidential Information</span>. Each Party agrees: (a) not to disclose any of the other Party&rsquo;s Confidential Information to any third parties except as mandated by law and except to those agents, advisors, or subcontractors who have a reasonable need to access such information, and who agree to be bound by confidentiality obligations no less stringent than those set forth in this Agreement; (b) not to use any of the other Party&rsquo;s Confidential Information for any purposes except carrying out such Party&rsquo;s rights and responsibilities under this Agreement; and (c) to keep the other Party&rsquo;s Confidential Information confidential using the same degree of care such Party uses to protect its own confidential information; provided, however, that such Party shall use at least reasonable care. These obligations shall survive termination of this Agreement. If a Party is required by law to disclose the other Party&rsquo;s Confidential Information, it will promptly notify the other Party (providing notice prior to disclosure if permitted by law), and provide reasonable assistance in seeking protection of such Confidential Information.</li>
              </ol>

              <ol start="5">
                <li>WARRANTIES</li>
                <li value={1}><span>Mutual Warranties</span>. Each Party represents and warrants to the other that: this Agreement has been duly executed and delivered and constitutes a valid and binding agreement enforceable against such Party in accordance with its terms, and no authorization or approval from any third party is required in connection with such Party&rsquo;s execution, delivery, or performance of this Agreement.</li>
              </ol>

              <ol start="6">
                <li>INTELLECTUAL PROPERTY INFRINGEMENT</li>
                <li value={1}><span>Defense and Indemnification</span>. Neon Coat shall, at its expense, either defend Organizer from or settle any claim, proceeding (whether in law or equity), or suit (&ldquo;Claim&rdquo;) brought by a third party against Organizer alleging that Organizer&rsquo;s use of the Neon Coat Services infringes or misappropriates any patent, copyright, trade secret, or trademark right of any third party, and indemnify Organizer from all damages, costs, and attorneys&rsquo; fees finally awarded in any such Claim or paid to any third party to settle any such Claim. Neon Coat&rsquo;s obligation under this section is contingent on (i) Organizer giving Neon Coat prompt written notice of the Claim; (ii) Organizer granting Neon Coat full and complete control over the defense and settlement of the Claim; and (iii) Organizer providing assistance in connection with the defense and settlement of the Claim as Neon Coat may reasonably request, at Neon Coat&rsquo;s cost. Organizer shall not defend or settle any Claim eligible for indemnification under this section without Neon Coat&rsquo;s prior written consent.</li>
                <li><span>Infringement Remedy</span>. If Organizer is enjoined or otherwise prohibited from using the Neon Coat Services or a portion thereof based on an allegation that the Neon Coat Services violate any third party intellectual property right (including a Claim), or if Neon Coat reasonably determines that such prohibition is likely, then Neon Coat shall, at its sole expense and option: (a) obtain for Organizer the right to use the allegedly infringing portions of the Neon Coat Services; (b) modify the allegedly infringing portions of the Neon Coat Services so as to render them non-infringing without substantially diminishing or impairing their functionality; or (c) replace the allegedly infringing portions of the Neon Coat Services with non-infringing items of substantially similar functionality.&nbsp;If Neon Coat determines that the foregoing remedies are not commercially reasonable, then Neon Coat may terminate this Agreement and shall promptly provide a prorated refund to Organizer for any prepaid fees received by Neon Coat for any Neon Coat Services that have not yet been performed at the time of such termination. </li>
                <li><span>Exclusions from Obligations</span>. Neon Coat shall have no obligation under this Section&nbsp;VI for any infringement or misappropriation to the extent that it arises out of or is based upon (i) use of the Neon Coat Services in combination with other products or services if such infringement or misappropriation would not have arisen but for such combination; (ii) use of the Neon Coat Services by Organizer for purposes not intended or outside the scope of the license granted to Organizer; (iii) Organizer&rsquo;s failure to use the Neon Coat Services in accordance with written instructions provided by Neon Coat, if the infringement or misappropriation would not have occurred but for such failure; or (iv) any modification of the Neon Coat Services not made or authorized in writing by Neon Coat where such infringement or misappropriation would not have occurred absent such modification.</li>
                <li><span>Limited Remedy</span>. This Section&nbsp;VI states Neon Coat&rsquo;s sole and exclusive liability, and Organizer&rsquo;s sole and exclusive remedy, for the actual or alleged infringement or misappropriation of any third party intellectual property right by the Neon Coat Services.</li>
              </ol>

              <ol start="7">
                <li>ORGANIZER INDEMNIFICATION</li>
                <li style={{listStyle: 'none'}}>Organizer shall defend Neon Coat from any actual or threatened third party Claim arising out of or based upon Organizer&rsquo;s breach of Section I of this Agreement, and indemnify Neon Coat from all damages, costs, and attorneys&rsquo; fees finally awarded in any such Claim or all amounts that Organizer agrees to pay to any third party to settle any such Claim. Organizer&rsquo;s obligation under this section is contingent on: (i)&nbsp;Neon Coat giving Organizer prompt written notice of the Claim; (ii)&nbsp;Neon Coat granting Organizer full and complete control over the defense and settlement of the Claim; (iii) Neon Coat providing assistance in connection with the defense and settlement of the Claim as Organizer may reasonably request; and (iv) Neon Coat&rsquo;s compliance with any settlement or court order made in connection with the Claim, provided that Organizer may not agree to any settlement that imposes any obligation on Neon Coat without Neon Coat&rsquo;s written consent. Neon Coat shall not defend or settle any Claim eligible for indemnification under this section without Organizer&rsquo;s prior written consent.</li>
              </ol>

              <ol start="8">
                <li>LIMITATIONS OF LIABILITY</li>
                <li value={1}><span>Disclaimer of Indirect Damages</span>. EXCEPT FOR LIABILITY ARISING OUT OF A BREACH OF SECTION IV, VIOLATION OF THE OTHER PARTY&rsquo;S INTELLECTUAL PROPERTY RIGHTS, OR EITHER PARTY&rsquo;S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, NEITHER PARTY WILL, UNDER ANY CIRCUMSTANCES, BE LIABLE TO THE OTHER PARTY FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THIS AGREEMENT, INCLUDING BUT NOT LIMITED TO LOST PROFITS OR LOSS OF BUSINESS, EVEN IF A PARTY IS APPRISED OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING.</li>
                <li><span>Limitation of Liability</span>. EXCEPT FOR LIABILITY ARISING OUT OF A BREACH OF SECTION IV, THE PARTIES&rsquo; INDEMNIFICATION OBLIGATIONS, VIOLATION OF THE OTHER PARTY&rsquo;S INTELLECTUAL PROPERTY RIGHTS, OR EITHER PARTY&rsquo;S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT, UNDER NO CIRCUMSTANCES WILL EITHER PARTY&rsquo;S TOTAL LIABILITY OF ALL KINDS ARISING OUT OF OR RELATED TO THIS AGREEMENT (INCLUDING BUT NOT LIMITED TO WARRANTY CLAIMS), REGARDLESS OF THE FORUM AND REGARDLESS OF WHETHER ANY ACTION OR CLAIM IS BASED ON CONTRACT, TORT, OR OTHERWISE, EXCEED THE TOTAL AMOUNT PAID BY ORGANIZER TO NEON COAT UNDER THE APPLICABLE SERVICE ORDER DURING THE TWELVE MONTHS PRECEDING THE CLAIM (DETERMINED AS OF THE DATE OF ANY FINAL JUDGMENT IN AN ACTION).</li>
                <li><span>Independent Allocations of Risk</span>. EACH PROVISION OF THIS AGREEMENT THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS OF THIS AGREEMENT BETWEEN THE PARTIES. THIS ALLOCATION IS REFLECTED IN THE PRICING OFFERED BY NEON COAT TO ORGANIZER AND IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE PARTIES. EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THIS AGREEMENT. THE LIMITATIONS IN THIS SECTION VIII WILL APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED REMEDY.</li>
              </ol>

              <ol start="9">
                <li>GENERAL</li>
                <li value={1}><span>Relationship</span>. This Agreement is not to be interpreted or construed as: (i) creating or evidencing any association, joint venture, partnership, or franchise between the Parties; (ii) imposing any partnership or franchise obligation or liability on either Party; or (iii) prohibiting or restricting either Party from collecting, licensing, or otherwise dealing in data or information from any third party.</li>
                <li><span>Assignability</span>. Neither Party may assign its right, duties, and obligations under this Agreement without the other Party&rsquo;s prior written consent, which consent will not be unreasonably withheld or delayed, except that a Party may assign this Agreement without the other Party&rsquo;s consent to a successor (including a successor by way of merger, acquisition, sale of assets, or operation of law) if the successor agrees to assume and fulfill all of the assigning Party&rsquo;s obligations under this Agreement.</li>
                <li><span>Notices</span>. Any notice required or permitted to be given in accordance with this Agreement will be effective if it is in writing and sent by e-mail, US mail, or insured courier, return receipt requested, to the appropriate Party at the address set forth on the signature page of this Agreement. Either Party may change its address for receipt of notice by notice to the other Party in accordance with this Section. Notices are deemed given two business days following the date of mailing or one business day following delivery to a courier or sending an email.</li>
                <li><span>Force Majeure</span>. Neither Party will be liable for, or be considered to be in breach of or default under this Agreement on account of, any delay or failure to perform as required by this Agreement as a result of any cause or condition beyond its reasonable control, so long as that Party uses all commercially reasonable efforts to avoid or remove the causes of non-performance.</li>
                <li><span>Governing Law</span>. This Agreement will be interpreted, construed, and enforced in all respects in accordance with the local laws of the State of New York, without reference to its choice of law rules. Each Party hereby irrevocably consents to the exclusive jurisdiction and venue of the federal, state, and local courts in New York County in connection with any action arising out of or in connection with this Agreement.</li>
                <li><span>Waiver</span>. The waiver by either Party of any breach of any provision of this Agreement does not waive any other breach. The failure of any Party to insist on strict performance of any covenant or obligation in accordance with this Agreement will not be a waiver of such Party&rsquo;s right to demand strict compliance in the future, nor will the same be construed as a novation of this Agreement.</li>
                <li><span>Severability</span>. If any part of this Agreement is found to be illegal, unenforceable, or invalid, the remaining portions of this Agreement will remain in full force and effect. If any material limitation or restriction on the use of the Neon Coat Services under this Agreement is found to be illegal, unenforceable, or invalid, Organizer&rsquo;s right to use the Neon Coat Services will immediately terminate.</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="col" />
      </div>
    </Layout>
  )
}

export default ServicesAgreement
